'use client'

import { useTranslations } from 'next-intl'
import { useEffect } from 'react'

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const t = useTranslations('common.Error')
  
  useEffect(() => {
    console.error(error)
  }, [error])
  
  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{t('somethingWentWrong')}</h1>
        <p className="text-lg text-gray-600 mb-8">{t('tryAgain')}</p>
        <button
          onClick={reset}
          className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition"
        >
          {t('tryAgain')}
        </button>
      </div>
    </div>
  )
}
